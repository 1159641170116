import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import RichText from '~components/RichText';
import Container from '~components/Container';
import RoundedLinkButton from '~components/buttons/RoundedLinkButton';
import ListItem from '~modules/tisha-bav-event/components/shared/ListItem';

const useStyles = makeStyles(theme => ({
  root: props => ({
    backgroundColor: props.background_color || 'transparent',
    color: props.text_color || '#000000',
    backgroundImage: `url(${props.background_image?.url})`,
    backgroundSize: 'contain',
    backgroundPosition: 'right bottom',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('xs')]: {
      backgroundImage: `none`,
      backgroundColor: props.background_color_mobile
    }
  }),
  mobileImage: {
    width: '100%'
  },
  container: {
    paddingTop: 100,
    paddingBottom: 100,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
      marginTop: -110,
      paddingBottom: 36
    }
  },
  label: props => ({
    marginTop: 0,
    marginBottom: 0,
    color: props.top_label_text_color || '#000000',
    backgroundColor: props.top_label_background_color || '#000000',
    fontSize: 20,
    fontFamily: 'Inter',
    fontWeight: '700',
    textTransform: 'uppercase',
    lineHeight: '140%',
    padding: '10px 24px',
    width: 'fit-content',
    borderRadius: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16
    }
  }),
  title: {
    maxWidth: 670,
    marginTop: 20,
    '& > h1': {
      marginTop: 0,
      marginBottom: 0,
      fontSize: 64,
      fontFamily: 'Inter',
      fontWeight: '700',
      lineHeight: '122%',
      [theme.breakpoints.down('xs')]: {
        fontSize: 52
      }
    },
    '& > h4': {
      marginTop: 0,
      marginBottom: 0,
      opacity: 0.7,
      fontSize: 19,
      fontFamily: 'Inter',
      fontWeight: '500',
      letterSpacing: 2.66,
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
        letterSpacing: 2.24
      }
    }
  },
  description: {
    maxWidth: 654,
    marginTop: 26,
    '& > *': {
      marginTop: 0,
      marginBottom: 0,
      fontSize: 16,
      fontFamily: 'Inter',
      fontWeight: '500',
      lineHeight: '170%',
      [theme.breakpoints.down('xs')]: {
        fontSize: 14
      }
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 50
    }
  },
  textUnderCta: {
    maxWidth: 654,
    '& > *': {
      marginTop: 0,
      marginBottom: 0,
      fontSize: 16,
      fontFamily: 'Inter',
      fontWeight: '500',
      lineHeight: '170%',
      [theme.breakpoints.down('xs')]: {
        fontSize: 14
      }
    },
    '& > * > a': {
      color: props => props.text_color || '#000000',
      fontWeight: '800',
      textDecoration: 'underline'
    }
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5
  },
  actionContainer: {
    marginTop: 48,
    display: 'flex',
    flexDirection: 'column',
    gap: 26,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: 30,
      gap: 13
    }
  },
  liveEventLabel: props => ({
    marginTop: 0,
    marginBottom: 0,
    color: props.live_event_label_color || '#000000',
    backgroundColor: props.live_event_label_color
      ? `${props.live_event_label_color}40`
      : '#00000040',
    fontSize: 17,
    fontFamily: 'Inter',
    fontWeight: '500',
    lineHeight: '140%',
    padding: '10px 24px',
    width: 'fit-content',
    borderRadius: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      textAlign: 'center',
      marginBottom: 10,
      maxWidth: 352
    }
  }),
  ctaButton: {
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'normal',
      textAlign: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '286px'
    }
  }
}));

const TishaBavHero = ({
  hero_title,
  hero_description,
  text_color,
  background_color,
  background_image,
  background_color_mobile,
  background_image_mobile,
  cta_path,
  cta_text,
  cta_theme,
  top_label_background_color,
  top_label_text,
  top_label_text_color,
  text_under_cta,
  is_event_live,
  live_event_label,
  live_event_label_color,
  list
}) => {
  const classes = useStyles({
    text_color,
    background_color,
    background_color_mobile,
    background_image,
    top_label_text_color,
    top_label_background_color,
    live_event_label_color
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

  return (
    <section className={classes.root}>
      {isMobile && background_image_mobile?.url && (
        <img
          src={background_image_mobile?.url}
          alt={background_image_mobile?.alt}
          className={classes.mobileImage}
        />
      )}
      <Container className={classes.container}>
        {top_label_text && <h3 className={classes.label}>{top_label_text}</h3>}
        <RichText html={hero_title?.html} externalClassName={classes.title} verticalSpacing={0} />
        {hero_description?.html && (
          <RichText
            html={hero_description?.html}
            externalClassName={classes.description}
            verticalSpacing={0}
          />
        )}

        {list?.length && (
          <div className={classes.listContainer}>
            {list.map(item => (
              <ListItem key={item?.list_item?.text} {...item} />
            ))}
          </div>
        )}

        <div className={classes.actionContainer}>
          {is_event_live && live_event_label && (
            <h5 className={classes.liveEventLabel}>{live_event_label}</h5>
          )}
          <RoundedLinkButton
            text={cta_text}
            to={cta_path}
            shouldTrackAnalytics
            analyticsEvent="Tisha Bav Button Click"
            analyticsTitle={cta_text}
            analyticsSection="TB Event Hero"
            size="small"
            variant={cta_theme}
            externalClassName={classes.ctaButton}
          />
          <RichText
            html={text_under_cta?.html}
            externalClassName={classes.textUnderCta}
            verticalSpacing={0}
          />
        </div>
      </Container>
    </section>
  );
};

TishaBavHero.propTypes = {
  hero_title: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string
  }).isRequired,
  hero_description: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string
  }).isRequired,
  background_color: PropTypes.string.isRequired,
  text_color: PropTypes.string.isRequired,
  background_image: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string
  }).isRequired,
  background_color_mobile: PropTypes.string.isRequired,
  background_image_mobile: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string
  }).isRequired,
  cta_path: PropTypes.string.isRequired,
  cta_text: PropTypes.string.isRequired,
  cta_theme: PropTypes.string.isRequired,
  text_under_cta: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string
  }),
  top_label_background_color: PropTypes.string,
  top_label_text: PropTypes.string,
  top_label_text_color: PropTypes.string,
  is_event_live: PropTypes.bool,
  live_event_label: PropTypes.string,
  live_event_label_color: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      heading_text: PropTypes.string,
      heading_text_color: PropTypes.string,
      list_item_color: PropTypes.string,
      list_item_icon: PropTypes.oneOf(['none', 'clock', 'calendar']),
      list_item: PropTypes.shape({
        html: PropTypes.string,
        text: PropTypes.string
      })
    })
  )
};

TishaBavHero.defaultProps = {
  text_under_cta: null,
  top_label_background_color: '',
  top_label_text: '',
  top_label_text_color: '',
  is_event_live: false,
  live_event_label: '',
  live_event_label_color: '',
  list: []
};

export default TishaBavHero;
