import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import RichText from '~components/RichText';
import CalendarIcon from '~images/icons/calendar-grey.svg';
import ClockIcon from '~images/icons/clock-grey.svg';

const useStyles = makeStyles(theme => ({
  title: props => ({
    marginTop: 24,
    marginBottom: 5,
    color: props.heading_text_color || '#000000',
    fontSize: 17,
    fontFamily: 'Inter',
    fontWeight: '600',
    lineHeight: '170%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  }),
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: 7,
    alignItems: 'center'
  },
  listItemText: props => ({
    color: props.list_item_color || '#000000',
    '& > *': {
      marginTop: 0,
      marginBottom: 0,
      fontSize: 16,
      fontFamily: 'Inter',
      fontWeight: '500',
      lineHeight: '170%',
      [theme.breakpoints.down('xs')]: {
        fontSize: 14
      }
    }
  })
}));

const getListIcon = icon => {
  switch (icon) {
    case 'calendar':
      return CalendarIcon;
    case 'clock':
      return ClockIcon;
    default:
      return null;
  }
};

const ListItem = ({
  disabled,
  heading_text,
  heading_text_color,
  list_item_color,
  list_item_icon,
  list_item
}) => {
  const classes = useStyles({
    disabled,
    heading_text_color,
    list_item_color
  });

  const icon = getListIcon(list_item_icon);

  return (
    <div>
      {heading_text && <h4 className={classes.title}>{heading_text}</h4>}
      <div className={classes.listItem}>
        {icon && <img src={icon} alt="list-icon" />}
        <RichText
          html={list_item?.html}
          externalClassName={classes.listItemText}
          verticalSpacing={0}
        />
      </div>
    </div>
  );
};

ListItem.propTypes = {
  disabled: PropTypes.bool,
  heading_text: PropTypes.string,
  heading_text_color: PropTypes.string,
  list_item_color: PropTypes.string,
  list_item_icon: PropTypes.oneOf(['none', 'clock', 'calendar']),
  list_item: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string
  }).isRequired
};

ListItem.defaultProps = {
  disabled: false,
  heading_text: '',
  heading_text_color: '#000000',
  list_item_color: '#000000',
  list_item_icon: ''
};

export default ListItem;
